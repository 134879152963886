import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OfferRequest } from "../models/offer_request.model";
import { AngularFireFunctions } from "@angular/fire/compat/functions";

@Injectable({
  providedIn: 'root'
})
export class EstimateService {
  private calculateAppCostUrl = 'https://calculateappcosts-hguihmnewq-ew.a.run.app';
  private calculateCustomAppCostUrl = 'https://calculatemodulescosts-hguihmnewq-ew.a.run.app';
  private submitOfferUrl = 'https://sendemailpdfoffer-hguihmnewq-ew.a.run.app';
  private predictionUrl = 'https://getmodulestouse-hguihmnewq-ew.a.run.app';

  constructor(private firestore: AngularFirestore,
              private functions: AngularFireFunctions,
              private http: HttpClient) {
  }

  loadApplications() {
    return this.firestore.collection('apps').snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      })),
      catchError(error => {
        console.error('Error loading applications:', error);
        return of(null); // Return null if an error occurs
      })
    );
  }

  loadApplication(appKey: string): Observable<any> {
    return this.firestore.collection(`apps`).doc(appKey).snapshotChanges().pipe(
      map(doc => {
        const data = doc.payload.data() as any;
        data.key = doc.payload.id;
        return {...data};
      })
    );
  }

  loadApplicationPrediction(prompt: string): Observable<any> {
    return this.http.post<any>(
      this.predictionUrl,
      {message: prompt}
    );
  }

  loadApplicationModules(appKey: string) {
    return this.firestore.collection(`/apps/${appKey}/modules`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  loadApplicationModule(appKey: string, moduleKey: string) {
    return this.firestore.collection(`/apps/${appKey}/modules`).doc(moduleKey).snapshotChanges().pipe(
      map(doc => {
        const data = doc.payload.data() as any;
        data.key = doc.payload.id;
        return {...data};
      }))
  }

  loadApplicationModuleFeatures(appKey: string, moduleKey: string) {
    return this.firestore.collection(`/apps/${appKey}/modules/${moduleKey}/features`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  loadApplicationModuleFeatureTasks(appKey: string, moduleKey: string, featureKey: string) {
    return this.firestore.collection(`/apps/${appKey}/modules/${moduleKey}/features/${featureKey}/tasks`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  computeApplicationCost(appKey: string) {
    return this.http.post<{ team: any, duration: number, total: number }>(this.calculateAppCostUrl, {appKey});
  }

  loadModules() {
    return this.firestore.collection(`modules`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  loadModule(moduleKey: string): Observable<any> {
    return this.firestore.collection('modules').doc(moduleKey)
      .snapshotChanges().pipe(
        map(doc => {
          const data = doc.payload.data() as any;
          data.key = doc.payload.id;
          return {...data};
        })
      );
  }

  loadModuleFeatures(moduleKey: string) {
    return this.firestore.collection(`/modules/${moduleKey}/features`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  loadModuleFeatureTasks(moduleKey: string, featureKey: string) {
    return this.firestore.collection(`/modules/${moduleKey}/features/${featureKey}/tasks`).snapshotChanges().pipe(
      map(snapshots => snapshots.map(snapshot => {
        const data = snapshot.payload.doc.data() as any;
        data.key = snapshot.payload.doc.id;
        return {...data};
      }))
    );
  }

  computeModulesCost(moduleKeys: any[]) {
    return this.http.post(this.calculateCustomAppCostUrl, {modules: moduleKeys});
  }

  submitOffer(offerRequest: OfferRequest) {
    return this.http.post(this.submitOfferUrl, {
      email: offerRequest.email,
      description: offerRequest.description,
      modules: offerRequest.modules
    });
  }
}
