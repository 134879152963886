<ng-container>

  <form class="deconstruct" [formGroup]="deconstructForm">

    <section id="title">
      <p class="name">{{ 'pages.estimate.applications.custom.title' | translate }}</p>
      <p class="description"
         *ngIf="!(hasPrediction$ | async)">{{ 'pages.estimate.applications.custom.longDescription' | translate }}</p>
    </section>

    <ng-container *ngIf="(hasPrediction$| async);then step2 else step1"></ng-container>

  </form>
  <!-- Overlay screen -->
  <div *ngIf="(isLoading$ | async)" id="overlay">
    <app-loading-spinner></app-loading-spinner>
  </div>

</ng-container>


<ng-template #step1>
  <section id="concept">
    <h2 class="title" [innerHTML]="'pages.estimate.applications.custom.step1.title' | translate"></h2>
    <p [innerHTML]="'pages.estimate.applications.custom.step1.description' | translate"></p>
    <app-input-textarea
      [showValidations]="showValidations || (control('description').invalid && control('description').touched)"
      [formControl]="control('description')"
      [maxLength]="300"
      placeholder="{{'pages.estimate.applications.custom.step1.descriptionPlaceholder' | translate}}">
    </app-input-textarea>
    <p class="disclaimer">{{ 'pages.estimate.applications.custom.step1.disclaimer' | translate }}</p>
    <app-button id="submit-prompt-btn"
                icon="bolt"
                [inverted]="true"
                (appClick)="requestPrediction()">
      {{ 'pages.estimate.applications.custom.step1.predictionButton' | translate }}
    </app-button>
  </section>
</ng-template>

<ng-template #step2>
  <app-button id="reset-prompt-btn"
              icon="undo"
              [inverted]="true"
              (appClick)="reloadPrediction()">
    {{ 'pages.estimate.applications.custom.step1.redescribe' | translate }}
  </app-button>

  <section id="construct">
    <h2 class="title">{{ 'pages.estimate.applications.custom.step2.title' | translate }}</h2>
    <p [innerHTML]="'pages.estimate.applications.custom.step2.description' | translate"></p>
    <p [innerHTML]="'pages.estimate.applications.custom.step2.clickIndication' | translate"></p>
    <p [innerHTML]="'pages.estimate.applications.custom.step2.pressIndication' | translate"></p>
    <div class="modules">
      <ng-container *ngFor="let module of modules$ | async; let index = index">
        <app-circle-card flavor="module"
                         name="{{('pages.estimate.modules.' + module.key + '.name') | translate}}"
                         content="{{module.icon}}"
                         [fixed]="isWeb"
                         [selected]="isModuleSelected(module)"
                         (pressed)="onModulePress(module)"
                         (clicked)="onModuleClick(module)">
        </app-circle-card>
      </ng-container>
    </div>
    <br/>
    <app-form-error-list
      *ngIf="showValidations && (!control('modules').valid)"
      [errorMap]="control('modules').errors">
    </app-form-error-list>
  </section>

  <section id="costs">
    <h2 class="title">{{ 'pages.estimate.applications.custom.step3.title' | translate }}</h2>
    <p [innerHTML]="'pages.estimate.applications.custom.step3.description' | translate"></p>
    <app-input type="email"
               [formControl]="control('email')"
               [showValidations]="showValidations || (control('email').invalid && control('email').touched)"
               label="{{'ux.inputs.email' | translate}}">
    </app-input>
    <app-checkbox [formControl]="control('tanc')"
                  labelPosition="after"
                  [showValidations]="showValidations || (control('tanc').invalid && control('tanc').touched)">
      <ng-container content>
          <span class="terms">{{ 'general.texts.iAgreeWith' | translate }}
            <a href="">{{ 'general.texts.termsAndConditions' | translate }}</a>
            {{ 'general.texts.and' | translate }}
            <a href="">{{ 'general.texts.privacyPolicy' | translate }}</a>
          </span>
      </ng-container>
    </app-checkbox>
    <app-button id="submit-estimate-btn"
                icon="send"
                [inverted]="true"
                (appClick)="onSubmit()">
      {{ 'pages.estimate.applications.custom.step3.submit' | translate }}
    </app-button>
  </section>
</ng-template>
