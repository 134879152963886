import { createAction, props } from '@ngrx/store';
import { Application } from "../models";
import { Module } from "../models";
import { ApplicationCost } from "../models/applicationCost.model";
import { OfferRequest } from "../models/offer_request.model";

// Load Applications
export const loadApplications = createAction(
  '[Estimate] Load Applications'
);

export const loadApplicationsSuccess = createAction(
  '[Estimate] Load Applications Success',
  props<{ apps: Application[] }>()
);

export const loadApplicationsFailed = createAction(
  '[Estimate] Load Applications Failure',
  props<{ error: any }>()
);


// Load Application
export const loadApplication = createAction(
  '[Estimate] Load Application',
  props<{ key: string }>()
);

export const loadApplicationSuccess = createAction(
  '[Estimate] Load Application Success',
  props<{ selectedApp: Application }>()
);

export const loadApplicationFailed = createAction(
  '[Estimate] Load Application Failure',
  props<{ error: any }>()
);

// Load Applications
export const loadApplicationPrediction = createAction(
  '[Estimate] Load Application Prediction',
  props<{ prompt: string }>()
);

export const loadApplicationPredictionSuccess = createAction(
  '[Estimate] Load Application Prediction Success',
  props<{ prediction: Module[] }>()
);

export const loadApplicationPredictionFailed = createAction(
  '[Estimate] Load Application Prediction Failure',
  props<{ error: any }>()
);

// Reload Prediction
export const reloadPrediction = createAction(
  '[Estimate] Reload Application Prediction',
);

// Load Application Module
export const loadApplicationModule = createAction(
  '[Estimate] Load Application Module',
  props<{ appKey: string, moduleKey: string }>()
);

export const loadApplicationModuleSuccess = createAction(
  '[Estimate] Load Application Module Success',
  props<{ selectedModule: Module }>()
);

export const loadApplicationModuleFailed = createAction(
  '[Estimate] Load Application Module Failure',
  props<{ error: any }>()
);

// Load All Modules
export const loadModules = createAction(
  '[Estimate] Load Modules',
);

export const loadModulesSuccess = createAction(
  '[Estimate] Load Modules Success',
  props<{ modules: Module[] }>()
);

export const loadModulesFailed = createAction(
  '[Estimate] Load Modules Failure',
  props<{ error: any }>()
);

// Load Module
export const loadModule = createAction(
  '[Estimate] Load Module',
  props<{ key: string }>()
);

export const loadModuleSuccess = createAction(
  '[Estimate] Load Module Success',
  props<{ selectedModule: Module }>()
);

export const loadModuleFailed = createAction(
  '[Estimate] Load Module Failure',
  props<{ error: any }>()
);

// Calculate Custom Application Costs
export const calculateCustomAppCost = createAction(
  '[Estimate] Calculate Custom App Costs',
  props<{ moduleKeys: string[] }>()
);

export const calculateCustomAppCostSuccess = createAction(
  '[Estimate] Calculate Custom App Costs Success',
  props<{ customAppCost: ApplicationCost }>()
);

export const calculateCustomAppCostFailed = createAction(
  '[Estimate] Calculate Module Costs Failed',
  props<{ error: any }>()
);

// Submit Offer
export const submitOffer = createAction(
  '[Estimate] Submit new offer',
  props<{ offerRequest: OfferRequest }>()
);

export const submitOfferSuccess = createAction(
  '[Estimate] Calculate Custom App Costs Success',
  props<{ response: any }>()
);

export const submitOfferFailed = createAction(
  '[Estimate] Calculate Module Costs Failed',
  props<{ error: any }>()
);
