<mat-form-field [ngClass]="{'open': auto.isOpen}">
  <input type="text"
         #autocompleteInput
         matInput
         [formControl]="formControl"
         [placeholder]="label"
         [matAutocomplete]="auto"
         (input)="onInputChange($event)"
         (blur)="onBlur()"
         (click)="onFocus()">
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="withObjects ? displayFn : null"
                    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions"
                [value]="option">
      {{ withObjects ? option[displayField] : option }}
    </mat-option>
  </mat-autocomplete>
  <mat-icon matSuffix>{{ selectedValue ? 'chevron_left' : 'expand_more' }}</mat-icon>
</mat-form-field>
<app-form-error-list *ngIf="showErrors && !auto.isOpen"
                     [errorMap]="formControl.errors"></app-form-error-list>
