<mat-form-field class="textarea-field"
                (click)="enable()"
                (blur)="onBlur()"
                (focusout)="disable()">
  <textarea matInput
            #textarea
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="minRows"
            [cdkAutosizeMaxRows]="maxRows"
            (blur)="onBlur()"
            (mouseenter)="onMouseEnter()"
            (mouseleave)="onMouseExit()"
            [formControl]="formControl"
            [rows]="maxRows"
            [maxlength]="maxLength"
            [placeholder]="placeholder"
            [readonly]="isReadOnly">
    </textarea>
  <mat-icon class="input-icon"
            [class.material-icons-outlined]="!isValid"
            [class.material-icons]="isValid"
            matSuffix>description
  </mat-icon>
  <mat-hint *ngIf="hovered" align="end">{{ textarea.value?.length || 0 }}/{{ maxLength }}</mat-hint>
</mat-form-field>
<app-form-error-list *ngIf="showErrors"
                     [errorMap]="formControl.errors">
</app-form-error-list>
