import * as estimateActions from './estimate.actions';

import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { Module } from "../models";
import { Application } from "../models";
import { ApplicationCost } from "../models/applicationCost.model";

export const estimateFeatureKey = 'estimate';

export interface EstimateState {
  apps: Application[];
  modules: Module[];
  prediction: Module[];
  hasPrediction: boolean;
  selectedApp: Application;
  selectedModule: Module;
  customAppCost: ApplicationCost;
  offerComputed: boolean,
  isLoading: boolean;
  error: any;
}

export const initialEstimateState: EstimateState = {
  apps: [],
  modules: [],
  prediction: [],
  hasPrediction: false,
  selectedApp: null,
  selectedModule: null,
  customAppCost: null,
  offerComputed: false,
  isLoading: false,
  error: null
};

const estimateReducer = createReducer(
  initialEstimateState,

  on(estimateActions.loadApplications, state => ({
    ...state,
    apps: [],
    isLoading: true
  })),
  on(estimateActions.loadApplicationsSuccess, (state, {apps}) => ({
    ...state,
    apps: apps,
    isLoading: false
  })),
  on(estimateActions.loadApplicationsFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(estimateActions.loadApplication, state => ({
    ...state,
    selectedApp: null,
    isLoading: true
  })),
  on(estimateActions.loadApplicationSuccess, (state, {selectedApp}) => ({
    ...state,
    selectedApp: selectedApp,
    isLoading: false
  })),
  on(estimateActions.loadApplicationFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(estimateActions.loadApplicationModule, state => ({
    ...state,
    selectedModule: null,
    isLoading: true
  })),
  on(estimateActions.loadApplicationModuleSuccess, (state, {selectedModule}) => ({
    ...state,
    selectedModule: selectedModule,
    isLoading: false
  })),
  on(estimateActions.loadApplicationModuleFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),


  on(estimateActions.loadModules, state => ({
    ...state,
    modules: [],
    isLoading: true
  })),
  on(estimateActions.loadModulesSuccess, (state, {modules}) => ({
    ...state,
    modules: modules,
    isLoading: false
  })),
  on(estimateActions.loadModulesFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(estimateActions.loadModule, state => ({
    ...state,
    selectedModule: null,
    isLoading: true
  })),
  on(estimateActions.loadModuleSuccess, (state, {selectedModule}) => ({
    ...state,
    selectedModule: selectedModule,
    isLoading: false
  })),
  on(estimateActions.loadModuleFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(estimateActions.calculateCustomAppCost, state => ({
    ...state,
    customAppCost: null,
    isLoading: true
  })),
  on(estimateActions.calculateCustomAppCostSuccess, (state, {customAppCost}) => ({
    ...state,
    customAppCost: customAppCost,
    isLoading: false
  })),
  on(estimateActions.calculateCustomAppCostFailed, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(estimateActions.submitOffer, state => ({
    ...state,
    offerComputed: false,
    isLoading: true
  })),
  on(estimateActions.submitOfferSuccess, (state, {response}) => ({
    ...state,
    offerComputed: true,
    hasPrediction: false,
    isLoading: false
  })),
  on(estimateActions.submitOfferFailed, (state, {error}) => ({
    ...state,
    offerComputed: false,
    error,
    isLoading: false
  })),

  on(estimateActions.loadApplicationPrediction, state => ({
    ...state,
    prediction: [],
    hasPrediction: false,
    isLoading: true
  })),
  on(estimateActions.loadApplicationPredictionSuccess, (state, {prediction}) => ({
    ...state,
    prediction: prediction,
    hasPrediction: true,
    isLoading: false
  })),
  on(estimateActions.loadApplicationPredictionFailed, (state, {error}) => ({
    ...state,
    error,
    hasPrediction: false,
    isLoading: false
  })),
  on(estimateActions.reloadPrediction, (state) => ({
    ...state,
    hasPrediction: false,
  })),
);

export function reducer(state: EstimateState | undefined, action: Action) {
  return estimateReducer(state, action);
}

export const getEstimateState = createFeatureSelector<EstimateState>(estimateFeatureKey);
export const getApps = createSelector(getEstimateState, (state: EstimateState) => state.apps);
export const getModules = createSelector(getEstimateState, (state: EstimateState) => state.modules);
export const getPrediction = createSelector(getEstimateState, (state: EstimateState) => state.prediction);
export const getHasPrediction = createSelector(getEstimateState, (state: EstimateState) => state.hasPrediction);
export const getSelectedApp = createSelector(getEstimateState, (state: EstimateState) => state.selectedApp);
export const getSelectedModule = createSelector(getEstimateState, (state: EstimateState) => state.selectedModule);
export const getCustomAppCost = createSelector(getEstimateState, (state: EstimateState) => state.customAppCost);
export const isOfferComputed = createSelector(getEstimateState, (state: EstimateState) => state.offerComputed);

export const isLoading = createSelector(getEstimateState, (state: EstimateState) => state.isLoading);
export const getError = createSelector(getEstimateState, (state: EstimateState) => state.error);
